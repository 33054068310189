import type { ContentfulLocaleCode } from '~/lib/ContentfulService/model';

export function isDownloadEntryModel(
  input: unknown
): input is DownloadEntryModel {
  return (
    typeof input === 'object' &&
    input != null &&
    '_type' in input &&
    input._type == 'DownloadEntryModel'
  );
}

export type DownloadEntryModel = {
  id: string;
  /**
   * Might contain HTML Elements!
   * @example '<p>The CAD drawing of the Moritex Light Spot MCEP contains a technical drawing of the product in 2D as well as a volume model in 3D.</p>'
   */
  abstract?: string;
  /**
   * @example 2024
   */
  dateYear?: string;
  /**
   * Without prepended "."
   *
   * @example "pdf"
   */
  fileExtension?: string;
  downloadCategory?: string;
  readableFilesize?: string;
  formRequired?: boolean;
  isExternalURL?: boolean;
  name?: string;
  documentNumber?: string;
  mediaLink: Partial<Record<ContentfulLocaleCode, string>>;
  metaDescription?: string;
  allowedLanguages?: ContentfulLocaleCode[];
  _type: 'DownloadEntryModel';
};
